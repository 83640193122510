import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import CheckoutPaymentProcessingScreen from '../../screens/checkout-payment-processing'

const CheckoutPaymentProcessingPage = (props) => (
  <Router>
    <CheckoutPaymentProcessingScreen
      {...props}
      path={`${_.get(props, 'pageContext.locale')}/checkout/payment-processing/`}
    />
    <CheckoutPaymentProcessingScreen
      {...props}
      path={`${_.get(props, 'pageContext.locale')}/checkout/payment-processing/encoded/:base64PaymentData`}
    />
  </Router>
)

export default CheckoutPaymentProcessingPage
